import React from "react";
import { useState } from "react";

import CheckoutCard from "./CheckoutCard";

import './Checkout.css'
import logosvg from '../../assets/Whiplist.svg'
import FeatureChart from "../../components/FeatureChart";

export default function Checkout (plan) {

    const [active, setActive] = useState(true)

    console.log(plan)

    const monthlyPro = {
        name: "Pro", 
        subPrice: "9", 
        stickerPrice: "Free", 
        description: "For multiple cars or to make money with Whiplist", 
        label: "Recommended", 
        included: "Everything in Starter, plus"
    }
    const monthlyStarter = {
        name: "Starter", 
        subPrice: "5", 
        stickerPrice: "$5", 
        description: "Fully customize and control your Whiplist", 
        label: "Most Popular", 
        included: "Everything in Free, plus"
    }

    const free = {
        name: "Free", 
        subPrice: "0", 
        stickerPrice: "$15", 
        description: "Create your own basic Whiplist profile and follow your favorite users", 
        included: "Included with Free"
    }

    const annuallyPro = {
        name: "Pro", 
        subPrice: "7.20", 
        stickerPrice: "Free", 
        description: "For multiple cars or to make money with Whiplist", 
        label: "Recommended", 
        included: "Everything in Starter, plus"
    }
    const annuallyStarter = {
        name: "Starter", 
        subPrice: "4", 
        stickerPrice: "Free", 
        description: "Fully customize and control your Whiplist", 
        label: "Most Popular", 
        included: "Everything in Free, plus"
    }

    const handleMonthlyClick = (e) => {
        setActive(true)
    }

    const handleAnnuallyClick = (e) => {
        setActive(false)
    }
    
    const handleDrag = (e) => {
        e.preventDefault()
    }

    return (
        <div className="checkout">

            <div className="checkout-left">

                <div className="checkoutlogosvg">
                    <img src={logosvg} alt="Whip List" onDragStart={handleDrag}></img>
                </div>

                <div className="options">
                    <p className="checkout-title">Pick your plan</p>
                    <p className="checkout-subtitle">You can change at any time.</p>
                </div>

                <div className="checkout-button-container">
                    {active && <div className="checkout-button-active" onClick={handleMonthlyClick}><p>Monthly</p></div>}
                    {active && <div className="checkout-button" onClick={handleAnnuallyClick}><p>Annually</p></div>}

                    {!active && <div className="checkout-button" onClick={handleMonthlyClick}><p>Monthly</p></div>}
                    {!active && <div className="checkout-button-active" onClick={handleAnnuallyClick}><p>Annually</p></div>}
                </div>

                <div className="checkout-cards-container">

                    {active && <div className="monthly">
                        
                        <CheckoutCard info={monthlyPro}/>
                        <CheckoutCard info={monthlyStarter}/>
                        <CheckoutCard info={free}/>
                    
                    </div>}

                    {!active && <div className="annually">

                        <CheckoutCard info={annuallyPro}/>
                        <CheckoutCard info={annuallyStarter}/>
                        <CheckoutCard info={free}/>
                        
                    </div>}

                </div>

            </div>

            <div className="chart-container">
                <FeatureChart/>
            </div>
            

        </div>
    )
} 