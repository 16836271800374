import React from 'react'
import { useState } from 'react'
import { useLogin } from '../../hooks/useLogin'
import { Link, useHistory } from 'react-router-dom'
import logosvg from "../../assets/Whiplist.svg"

import "./Login.css"

export default function Login () {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {login, isPending, error} = useLogin()
    const history = useHistory()

    const handleSubmit = (e) => {
        e.preventDefault()
        login(email, password)
    }

    const handleClick = (e) => {
        history.push("/")
    }

    const handleDrag = (e) => {
        e.preventDefault()
    }

    return (
        <form onSubmit={handleSubmit} className="backgroundgradient">

            <div className="up-top">

                <div className="up-top-text">Log in to</div>

                <div className="logosvg">
                    <img src={logosvg} alt="WHIP_LIST_LOGO" className="login-logo" onClick={handleClick} onDragStart={handleDrag}></img>
                </div>

            </div>

            <div className="the-form">
                <label>
                    <input 
                    required
                    className="field"
                    type="email"
                    placeholder="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    ></input>
                </label>

                <label>
                    <input 
                    required
                    className="field"
                    type="password"
                    placeholder="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    ></input>
                    {error && <div className="error">{error}</div>}
                </label>

                {isPending && <button className="btn" disabled>Loading</button>}
                {!isPending && <button className="btn">Login</button>}

                <div className="links">
                    <div className="link">
                        Don't have an account? <Link to="/" className="clickableLink">Create one </Link>
                    </div>

                    <div className="last-link">
                        <Link to="/reset" className="clickableLink">Forgot password?</Link>
                    </div>
                </div>
            </div>
        </form>
    )
}