import React from "react";

export default function CheckoutCard ({info}) {

    const name=info.name
    const subPrice = info.subPrice
    const stickerPrice = info.stickerPrice
    const description = info.description
    const label = info.label
    const included = info.included

    return (
        <div className="checkout-card">

            {label && <div className="checkout-label">{label}</div>}

            <div className="checkout-card-header">

                <div className="checkout-card-header-section">
                    <div>
                        <p>{name}</p>
                    </div>
                    <div>

                    </div>
                </div>
                
                <div className="checkout-card-header-section">
                    <div>
                        <span className="bolded">${subPrice}</span>
                        <span> USD/Month</span>
                    </div>
                    <div>
                        <span>{stickerPrice} QR Sticker</span>
                    </div>
                </div>

                <div className="circle"></div>

            </div>

            <div className="checkout-card-content">

                <p className="checkout-description">{description}</p>

                <span className="included">{included}</span>
                <span className="this-is-a-placeholder">       V</span>

            </div>

        </div>
    )
}