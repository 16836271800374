import React from "react"
import { useHistory } from "react-router-dom"

export default function Page3 () {

    const history = useHistory()

    const handleSubmit = (e) => {
        e.preventDefault()
        history.push("/signup")
    }

    return (
        <div className="page3">

            <div className="page3-content">

                <div className="page3-title">
                    <p className="page3-title-text">Pick your QR</p>
                    <p className="page3-title-text">code color &</p>
                    <p className="page3-title-text">customize your</p>
                    <p className="page3-title-text">page in minutes.</p>
                </div>

                <p className="page3-description">You will receive your waterproof QR car stickers in one week or less.</p>
                <p className="page3-description">While you're waiting, create and design your Whip List page to match the style of you and your car.</p>
                <p className="page3-description">Now anyone can scan your Whip List QR or click your Whip List link to learn more about you and your car.</p>

                <button className="page3-cta" onClick={handleSubmit}>Get started for free</button>
                
            </div>
        </div>
    )
}