import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';

// styles
import './App.css'

// pages
import User from './pages/user/User';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';
import Funnel from './pages/funnel/Funnel';
import ResetPassword from './pages/resetPassword/ResetPassword';
//import Wip from './pages/wip/Wip';
import Pricing from './pages/pricing/Pricing';
import Checkout from './pages/checkout/Checkout';

import ScrollToTop from './components/ScrollToTop';

//TODO for user && signup the redirect needs to update the page

//TODO update default "/" route to be an explore page for logged in users

function App() {

  // grab user and make sure auth is ready before rendering page
  const { user, authIsReady } = useAuthContext()

  //<Route exact path="/">
  //            {user && <Redirect to={`/${user.displayName}`}></Redirect>}
  //            {!user && <Funnel />}
  //          </Route>

  return (
    <div className="App">

      {authIsReady && (

        <BrowserRouter>

          <ScrollToTop />
    
          <Switch>

            <Route exact path="/">
              <Funnel />
            </Route>

            <Route exact path="/login">
              {user && <Redirect to={`/${user.displayName}`}></Redirect>}
              {!user && <Login />}
            </Route>

            <Route exact path="/reset">
              {!user && <ResetPassword/>}
              {user && <Redirect to={`/${user.displayName}`}></Redirect>}
            </Route>

            <Route exact path="/signup">
              {!user && <Signup />}
              {user && <Redirect to="/checkout"/>}              
            </Route>

            <Route exact path="/home">
              <Redirect to="/"/>             
            </Route>

            <Route exact path="/pricing">
              {!user && <Pricing/>}
              {user && <Pricing/>}              
            </Route>

            <Route exact path="/checkout">
              {!user && <Checkout/>}
              {user && <Checkout/>}              
            </Route>

            <Route path="/:id">
              {<User />}
            </Route>

          </Switch>

        </BrowserRouter>

        )
      }
    </div>
  )
}
export default App
