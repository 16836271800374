import React, { useState } from "react"
import { useHistory } from "react-router-dom"

export default function Page1 () {
    const [desiredName, setDesiredName] = useState("")
    const history = useHistory()

    const handleSubmit = (e) => {
        e.preventDefault()
        history.push("/signup")
    }

    return (
        <div className="page1">

            <div className="page1-content">

                <div className="page1-title">
                    <p className="page1-title-text">Everything</p>
                    <p className="page1-title-text">about your car,</p>
                    <p className="page1-title-text">one scan away.</p>
                </div>

                <p className="page1-description">Share your car build and links with the world. All with a scalable QR code sticker or link in bio.</p>

                <form className="page1-form" onSubmit={handleSubmit}>
                    <label className="page1-label">
                    <input className="page1-name-box"
                        type="text"
                        placeholder="whiplist.io/yourname"
                        onChange={(e) => setDesiredName(e.target.value)}
                        value={desiredName}
                        ></input>
                        <button className="page1-cta">Claim your link</button>
                    </label>
                </form>

            </div>
        </div>
    )
}