import React from 'react'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import logosvg from "../assets/Whiplist.svg"
import menuIcon from "../assets/menu.svg"
import x from "../assets/x.svg"
import { useAuthContext } from '../hooks/useAuthContext'
import { useLogout } from '../hooks/useLogout'

import ig from "../assets/socials/ig.svg"
import tt from "../assets/socials/tt.svg"
import yt from "../assets/socials/yt.svg"
import tw from "../assets/socials/tw.svg"
import fb from "../assets/socials/fb.svg"

import "./Nav.css"

//document.addEventListener('logosvg', event => event.preventDefault());

export default function FunnelNav (color) {
    const history = useHistory()
    const [menu, setMenu] = useState(false)
    const { user } = useAuthContext()
    const { logout, isPending} = useLogout()

    const handleClick = (e) => {
        history.push("/")
    }

    const handleDrag = (e) => {
        e.preventDefault()
    }
    
    const showMenu = (e) => {
        setMenu(true)
    }

    const xOut = (e) => {
        setMenu(false)
    }

    return (
        <div style={{backgroundColor: `rgb(${color.color})`}} className="funnel-nav-main">
            <nav>
                <Link to="/" className="nav-whip-logo">
                    <div className="logosvg">
                        <img src={logosvg} alt="Whip List" onClick={handleClick} onDragStart={handleDrag}></img>
                    </div>
                </Link>
            
                <div className="nav-btn-div">
                    <button className="navbtn"><Link to="/signup" className="signup-link">Signup free</Link></button>
                </div>
                
                <div className="nav-menu-icon">
                    <img src={menuIcon} alt="Menu" onClick={showMenu}></img>
                </div>
            </nav>

            {menu && 
                
                <div className="menu-container">

                    <div className="inner-menu">

                        <div className="nav-menu-item"><Link to="/">Home</Link></div>

                        <div className="nav-menu-item sub">How it works</div>

                        <div className="nav-menu-item sub">Examples</div>

                        <div className="nav-menu-item sub">Features</div>

                        <div className="nav-menu-item sub">Have questions?</div>

                        <div className="nav-menu-item"><Link to="/pricing">Plans & pricing</Link></div>

                        {!user && <div className="member">Already a member? <Link to="/login">Log in</Link></div>}
                        {user && <div className="logout" onClick={logout}>Logout</div>}

                        <div className="follow">Follow us everywhere!</div>

                        <div className="menu-grid-container"> 

                            <div><a href="https://www.instagram.com/whiplist.io/" target="_blank"><img src={ig} alt="Instagram" className="invert" onDragStart={handleDrag}></img></a></div>
                            <div><a href="https://www.tiktok.com/@whiplist.io" target="_blank"><img src={tt} alt="Tik Tok" className="invert" onDragStart={handleDrag}></img></a></div>
                            <div><img src={yt} alt="YouTube" className="invert" onDragStart={handleDrag}></img></div>
                            <div><img src={tw} alt="Twitter" className="invert" onDragStart={handleDrag}></img></div>
                            <div><img src={fb} alt="Facebook" className="invert" onDragStart={handleDrag}></img></div>

                        </div>

                        <div className="xout"><img src={x} onClick={xOut} alt="x" className="invert" onDragStart={handleDrag}></img></div>


                    </div>
                </div>}
        </div>
    )
}