import React from 'react'
import { useParams } from "react-router-dom"
import BottomNav from "../../components/BottomNav"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useDocument } from '../../hooks/useDocument'

import './User.css'

import ig from "../../assets/socials/ig.svg"
import tt from "../../assets/socials/tt.svg"
import yt from "../../assets/socials/yt.svg"
import tw from "../../assets/socials/tw.svg"
import fb from "../../assets/socials/fb.svg"

export default function User () {
    const { user } = useAuthContext()
    const {id} = useParams()
    const {document} = useDocument('users', 'UnvSSFluPbfh3gOfGif2dNxf0fv2')

    //Logic for displaying bottom banner
    let myPage = false;
    if (user) {if (user.displayName === id) { myPage = true;}}

    //TODO Get doc ID of user record from usecollection, query for where username == id^

    //Get user data
    console.log(document)    

    const handleDrag = (e) => {
        e.preventDefault()
    }

    //                    <Socials socials={document.socials}/>


    return(
        <div className="userbackground">

            {document && 
                <div className="user-page-content">

                    <div className="banner">

                        <img src={document.bannerURL} alt="user banner"/>

                        <div className="avatar-div">

                            <div className="user-avatar">
                                <img src={document.photoURL} alt="user avatar"/>
                            </div>

                            <div className="avatar-text">
                                <span className="user-title">{`@${document.displayName}`}</span>
                                <span className="user-sub-title">{`${document.year} ${document.make} ${document.model}`}</span>
                                <span className="user-sub-title">{document.summary}</span>
                            </div>
                        </div>

                    </div>

                    <div className="user-socials-container"> 
                            <div><a href="https://www.instagram.com/whiplist.io/" target="_blank"><img src={ig} alt="Instagram" className="invert" onDragStart={handleDrag}></img></a></div>
                            <div><a href="https://www.tiktok.com/@whiplist.io" target="_blank"><img src={tt} alt="Tik Tok" className="invert" onDragStart={handleDrag}></img></a></div>
                            <div><img src={yt} alt="YouTube" className="invert" onDragStart={handleDrag}></img></div>
                            <div><img src={tw} alt="Twitter" className="invert" onDragStart={handleDrag}></img></div>
                            <div><img src={fb} alt="Facebook" className="invert" onDragStart={handleDrag}></img></div>
                    </div>

                    <div className="user-buttons">
                        <div className="user-button">
                            Parts List
                        </div>

                        <div className="user-button">
                            Specs
                        </div>

                        <div className="user-button">
                            Gallery
                        </div>

                        <div className="user-button">
                            Support The Build
                        </div>

                        <div className="user-button">
                            Bio
                        </div>
                    </div>
                    

                    
                </div>
            }
        </div>
    )
}