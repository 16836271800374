import React from "react"

import Nav from "../../components/Nav"
import FeatureChart from "../../components/FeatureChart"
import PricingButtons from "./PricingButtons"

import "./Pricing.css"

export default function Pricing () {

    return (
        <div>
            <Nav color="61, 190, 201"></Nav>
    
            <div className="main-page">

                <div className="main-body">
                
                    <div className="page-title">
                        <p>Compare</p>
                        <p>plans</p>
                    </div>

                    <div className="desktop-support">
                        <FeatureChart/>
                        <PricingButtons/>
                    </div>
                </div>
            </div>
        </div>
    )
}