import React from "react"
import { useHistory } from "react-router-dom"

export default function Page2 () {
    const history = useHistory()

    const handleSubmit = (e) => {
        e.preventDefault()
        history.push("/signup")
    }

    return (
        <div className="page2">

            <div className="page2-content">

                <div className="page2-title">
                    <p className="page2-title-text">Convert</p>
                    <p className="page2-title-text">passerby into</p>
                    <p className="page2-title-text">fan (touch free).</p>
                </div>

                <p className="page2-description">Showcase your mod list, favorite specs, grow your socials, and collect tips all in one place. Even with you not there, at a car show or parked at the beach, your car will do the talking.</p>

                <button className="page2-cta" onClick={handleSubmit}>Sign up now</button>

            </div>
        </div>
    )
}