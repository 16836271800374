import React from 'react'
import { useState } from 'react'
import { useSignup } from '../../hooks/useSignup'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import logosvg from '../../assets/Whiplist.svg'

// styles
import './Signup.css'

export default function Signup () {
    const [email, setEmail] = useState('')
    const [first, setFirst] = useState('')
    const [last, setLast] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const history = useHistory()
    const {signup, isPending, error } = useSignup()

    const handleSubmit = (e) => {
        e.preventDefault()
        signup(email, first, last, password, repeatPassword)
    }

    const handleClick = (e) => {
        history.push("/")
    }
    
    const handleDrag = (e) => {
        e.preventDefault()
    }

    return (
        <form className="signupgradient" onSubmit={handleSubmit}>

            <div className="main-body">

                <div className="signuplogosvg">
                    <img src={logosvg} alt="Whip List" onClick={handleClick} onDragStart={handleDrag}></img>
                </div>
                
                <h1 className="title">Create your account</h1>

                <label className="signuplabel">
                    <span>Email</span>
                    <input 
                    required
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    ></input>
                </label>

                <div className="names">
                    <label className="name">
                        <span>First name</span>
                        <input 
                        required
                        type="text"
                        onChange={(e) => setFirst(e.target.value)}
                        value={first}
                        ></input>
                    </label>
                    <label className="name">
                        <span>Last name</span>
                        <input 
                        required
                        type="text"
                        onChange={(e) => setLast(e.target.value)}
                        value={last}
                        ></input>
                    </label>
                </div>

                <label>
                    <span>Password</span>
                    <input 
                    required
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    ></input>
                </label>

                <p>Your password must be at lest 8 characters in length and cannot begin or end with a space</p>

                <label>
                    <span>Confirm password</span>
                    <input 
                    required
                    type="password"
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    value={repeatPassword}
                    ></input>
                    {error && <div className="error">{error}</div>}
                </label>

                {isPending && <button className="btn" disabled>Loading</button>}
                {!isPending && <button className="btn">Create account</button>}

                <div className="terms">
                    By proceeding, you agree to the Terms and Conditions and Privacy Policy
                </div>

                <div className="last-link">
                    Already have an account? <Link to="/login" className="clickableLink">Log in</Link>
                </div>

            </div>
        </form>
    )
}