import React from "react";
import { Link } from "react-router-dom";

import './PricingCard.css'

export default function PricingCard ({info}) {

    const name=info.name
    const subPrice = info.subPrice
    const stickerPrice = info.stickerPrice
    const description = info.description
    const label = info.label
    //const extra = info.extra

    return (
        <div className="card">

            <div className="card-header">

                <div className="card-header-section">
                    <div>
                        <p>{name}</p>
                    </div>
                    <div>

                    </div>
                </div>
                
                <div className="card-header-section">
                    <div>
                        <span className="bolded">${subPrice}</span>
                        <span> USD/Month</span>
                    </div>
                    <div>
                        <span>{stickerPrice} QR Sticker</span>
                    </div>
                </div>
            </div>

            <div className="card-content">

                <p className="description">{description}</p>

                {label && <div className="label">{label}</div>}

                <button className="get">
                    <Link to={{pathname: '/checkout', name}} className="get-link">Get {name}</Link>
                </button>

            </div>

        </div>
    )
}