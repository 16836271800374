import React from "react"

import { useState } from "react"
import expand from "../../assets/expand.svg"
import { useHistory } from "react-router-dom"

export default function Page4 () {

    const history = useHistory()

    const handleSubmit = (e) => {
        e.preventDefault()
        history.push("/signup")
    }

    const [currentState, setCurrentState]= useState({
        b1: true,
        b2: false,
        b3: false,
        b4: false,
        b5: false,
        b6: false,
        b7: false,
        b8: false,
        b9: false,
        b10: false
    })

    const handleClick = (num) => {

        switch (num){

            case 1:
                setCurrentState({
                    b1: true,
                    b2: false,
                    b3: false,
                    b4: false,
                    b5: false,
                    b6: false,
                    b7: false,
                    b8: false,
                    b9: false,
                    b10: false
                })
                return
            case 2: 
            setCurrentState({
                b1: false,
                b2: true,
                b3: false,
                b4: false,
                b5: false,
                b6: false,
                b7: false,
                b8: false,
                b9: false,
                b10: false
            })
                return
            case 3: 
            setCurrentState({
                b1: false,
                b2: false,
                b3: true,
                b4: false,
                b5: false,
                b6: false,
                b7: false,
                b8: false,
                b9: false,
                b10: false
            })
                return
            case 4: 
            setCurrentState({
                b1: false,
                b2: false,
                b3: false,
                b4: true,
                b5: false,
                b6: false,
                b7: false,
                b8: false,
                b9: false,
                b10: false
            })
                return
            case 5: 
            setCurrentState({
                b1: false,
                b2: false,
                b3: false,
                b4: false,
                b5: true,
                b6: false,
                b7: false,
                b8: false,
                b9: false,
                b10: false
            })
                return
            case 6: 
            setCurrentState({
                b1: false,
                b2: false,
                b3: false,
                b4: false,
                b5: false,
                b6: true,
                b7: false,
                b8: false,
                b9: false,
                b10: false
            })
                return
            case 7: 
            setCurrentState({
                b1: false,
                b2: false,
                b3: false,
                b4: false,
                b5: false,
                b6: false,
                b7: true,
                b8: false,
                b9: false,
                b10: false
            })
                return
            case 8: 
            setCurrentState({
                b1: false,
                b2: false,
                b3: false,
                b4: false,
                b5: false,
                b6: false,
                b7: false,
                b8: true,
                b9: false,
                b10: false
            })
                return
            case 9: 
            setCurrentState({
                b1: false,
                b2: false,
                b3: false,
                b4: false,
                b5: false,
                b6: false,
                b7: false,
                b8: false,
                b9: true,
                b10: false
            })
                return
            case 10: 
            setCurrentState({
                b1: false,
                b2: false,
                b3: false,
                b4: false,
                b5: false,
                b6: false,
                b7: false,
                b8: false,
                b9: false,
                b10: true
            })
                return
            default:
                return
        }
    }

    return (
        <div className="page4">

            <div className="page4-content">

                <div className="page4-title">
                    <p className="page4-title-text">High-octane</p>
                    <p className="page4-title-text">features</p>
                </div>

                <button className="page4-box" onClick={() => {handleClick(1)}}>
                    <p className="page4-box-title">Connect your links</p>
                    {!currentState.b1 && <img src={expand} alt="expand"></img>}
                    {currentState.b1 && <img src={expand} className="upside-down" alt="expand"></img>}
                    { currentState.b1 && <p className="page4-box-info">Add clickable links to all of your social medias on your page.</p>}
                </button>

                <button className="page4-box" onClick={() => {handleClick(2)}}>
                    <p className="page4-box-title">Show parts & mods</p>
                    {!currentState.b2 && <img src={expand} alt="expand"></img>}
                    {currentState.b2 && <img src={expand} className="upside-down" alt="expand"></img>}
                    { currentState.b2 && <p className="page4-box-info">List the parts in your build, add a photo of how it looks installed, tell a story or include install tips. Even make money by connecting your affiliate links.</p>}
                </button>

                <button className="page4-box" onClick={() => {handleClick(3)}}>
                    <p className="page4-box-title">Flex specs</p>
                    {!currentState.b3 && <img src={expand} alt="expand"></img>}
                    {currentState.b3 && <img src={expand} className="upside-down"alt="expand"></img>}
                    { currentState.b3 && <p className="page4-box-info">Show off the specs that are most important to you and your followers.</p>}
                </button>

                <button className="page4-box" onClick={() => {handleClick(4)}}>
                    <p className="page4-box-title">Collect tips</p>
                    {!currentState.b4 && <img src={expand} alt="expand"></img>}
                    {currentState.b4 && <img src={expand} className="upside-down" alt="expand"></img>}
                    { currentState.b4 && <p className="page4-box-info">Accept donations from users who want to support the build!</p>}
                </button>

                <button className="page4-box" onClick={() => {handleClick(5)}}>
                    <p className="page4-box-title">Design your own page</p>
                    {!currentState.b5 && <img src={expand} alt="expand"></img>}
                    {currentState.b5 && <img src={expand} className="upside-down" alt="expand"></img>}
                    { currentState.b5 && <p className="page4-box-info">Lay out your page however you'd like, with options to change the color scheme and the layout of your different links and pages.</p>}
                </button>

                <button className="page4-box" onClick={() => {handleClick(6)}}>
                    <p className="page4-box-title">Choose a custom URL</p>
                    {!currentState.b6 && <img src={expand} alt="expand"></img>}
                    {currentState.b6 && <img src={expand} className="upside-down" alt="expand"></img>}
                    { currentState.b6 && <p className="page4-box-info">Your URL is tied directly to your username with a paid account.</p>}
                </button>

                <button className="page4-box" onClick={() => {handleClick(7)}}>
                    <p className="page4-box-title">Write a bio</p>
                    {!currentState.b7 && <img src={expand} alt="expand"></img>}
                    {currentState.b7 && <img src={expand} className="upside-down" alt="expand"></img>}
                    { currentState.b7 && <p className="page4-box-info">Hook in potential listeners with a short summary of you and your car.</p>}
                </button>

                <button className="page4-box" onClick={() => {handleClick(8)}}>
                    <p className="page4-box-title">Boast dyno charts</p>
                    {!currentState.b8 && <img src={expand} alt="expand"></img>}
                    {currentState.b8 && <img src={expand} className="upside-down" alt="expand"></img>}
                    { currentState.b8 && <p className="page4-box-info">Add a button for pop up images to display your graphs.</p>}
                </button>

                <button className="page4-box" onClick={() => {handleClick(9)}}>
                    <p className="page4-box-title">Upload photo gallery</p>
                    {!currentState.b9 && <img src={expand} alt="expand"></img>}
                    {currentState.b9 && <img src={expand}  className="upside-down" alt="expand"></img>}
                    { currentState.b9 && <p className="page4-box-info">Upload all of your favorite angles, for all to see.</p>}
                </button>

                <button className="page4-box" onClick={() => {handleClick(10)}}>
                    <p className="page4-box-title">Display trophies or awards</p>
                    {!currentState.b10 && <img src={expand} alt="expand"></img>}
                    {currentState.b10 && <img src={expand}  className="upside-down" alt="expand"></img>}
                    { currentState.b10 && <p className="page4-box-info">Proudly display your most prestigious accolades.</p>}
                </button>

                <button className="page4-cta" onClick={handleSubmit}>Give them to me</button>

            </div>
        </div>
    )
}