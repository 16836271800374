import React from 'react'
import { useState, useEffect } from 'react'

import Nav from "../../components/Nav"
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'
import Page4 from './Page4'

import "./Funnel.css"

export default function Funnel () {

    const [offset, setOffset] = useState(0)
    const [lookup, setLookup] = useState("209, 97, 59")

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    //console.log(offset)

    useEffect(() => {
        if (offset > 2550){
            setLookup("231, 189, 69")
            return
        }
        if (offset > 1755){
            setLookup("48, 107, 61")
            return
        }
        if (offset > 770){
            setLookup("231, 189, 69")
            return
        }

        setLookup("209, 97, 59")
        
    }, [offset])

    return (
        <div>

            <Nav color={lookup}></Nav>
            
            <Page1/>
            <Page2/>
            <Page3/>
            <Page4 id="Features"/>

        </div>
    )
}