import React from 'react'

import './FeatureChart.css'
import arrow from "../assets/expand.svg"
import x from "../assets/x.svg"
import check from "../assets/check.svg"
import infinity from "../assets/infinity.svg"

export default function FeatureChart () {

    return (
        <div className="main-content">

            <div className="grid-labels">
                <div></div>
                <div></div>
                <div className="other-label"><div className="grid-label">Pro</div></div>
                <div className="starter"><div className="grid-label">Starter</div></div>
                <div className="other-label"><div className="grid-label">Free</div></div>
            </div>

            <div className="grid-container">

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Max # of Links</div></div>
                <div className="grid-item grid-item-3"><img src={infinity} alt="infinity" className="infinity"></img></div>
                <div className="grid-item grid-item-4"><img src={infinity} alt="infinity" className="infinity"></img></div>
                <div className="grid-item grid-item-5"><div className="chart-text">5</div></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label"># of Car Pages</div></div>
                <div className="grid-item grid-item-3"><div className="chart-text">+5</div></div>
                <div className="grid-item grid-item-4"><div className="chart-text">1</div></div>
                <div className="grid-item grid-item-5"><div className="chart-text">1</div></div>
                
                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Default Themes</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={check} alt="+" className="included"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Profile Picture</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={check} alt="+" className="included"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Profile Bio</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={check} alt="+" className="included"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Follow Whiplists</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={check} alt="+" className="included"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Platform Support</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={check} alt="+" className="included"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Custom Username</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={x} alt="x" className="notIncluded"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Advanced Themes</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={x} alt="x" className="notIncluded"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Small Icon Links</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={x} alt="x" className="notIncluded"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Custom Fonts</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={x} alt="x" className="notIncluded"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Custom Buttons</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={x} alt="x" className="notIncluded"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Parts List</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={x} alt="x" className="notIncluded"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Photo Gallery</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={x} alt="x" className="notIncluded"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Embed Videos</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={x} alt="x" className="notIncluded"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Showcase Awards</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={x} alt="x" className="notIncluded"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Hide Whiplist Logo</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={x} alt="x" className="notIncluded"></img></div>

                <div className="grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="grid-item grid-item-2"><div className="chart-label">Free QR Stickers</div></div>
                <div className="grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-4"><img src={check} alt="+" className="included"></img></div>
                <div className="grid-item grid-item-5"><img src={x} alt="x" className="notIncluded"></img></div>

                <div className="last-grid-item grid-item-1"><img src={arrow} alt="arrow" className="arrow"></img></div>
                <div className="last-grid-item grid-item-2"><div className="chart-label">Make $$$ With Us</div></div>
                <div className="last-grid-item grid-item-3"><img src={check} alt="+" className="included"></img></div>
                <div className="last-grid-item grid-item-4"><img src={x} alt="x" className="notIncluded"></img></div>
                <div className="last-grid-item grid-item-5"><img src={x} alt="x" className="notIncluded"></img></div>

            </div>

        </div>
    )
}