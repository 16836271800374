import { useState } from 'react'
import { projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useSignup = () => {
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const signup = async (email, first, last, password, repeatPassword) => {
    setError(null)
    setIsPending(true)
  
    try {

      if (password !== repeatPassword){
        setTimeout(() => {setError(null)}, 5000)
        throw new Error("Passwords do not match!")
      }

      // signup
      const res = await projectAuth.createUserWithEmailAndPassword(email, password)

      if (!res) {
        throw new Error('Could not complete signup')
      }

      // add display name to user
      await res.user.updateProfile({ firstName: first, lastName: last })


      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })


      setIsPending(false)
      setError(null)

    } 
    catch(err) {
      setError(err.message)
      setIsPending(false)

      //Only show message for 5 seconds
      setTimeout(() => {setError(null)}, 5000)
    }
  }

  return { signup, error, isPending }
}