// import firebase
import firebase from "firebase/app";

// import any services that we need
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// config object grabbed from firebase settings cog next to front end app 
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDd7ZuGMQOUnlXIB6QxivijFw_vZ39CTeE",
  authDomain: "whiplist.io",
  projectId: "whiplist-fee37",
  storageBucket: "whiplist-fee37.appspot.com",
  messagingSenderId: "594917427660",
  appId: "1:594917427660:web:f6d04652078b7c5912f569",
  measurementId: "G-3J866H2WXE"
};

  // init firebase
  firebase.initializeApp(firebaseConfig)

  // init services
  const projectFirestore = firebase.firestore()
  const projectAuth = firebase.auth()
  const projectStorage = firebase.storage()

  const perf = firebase.performance;
  const analytics = firebase.analytics;

  // timestamp
  const timestamp = firebase.firestore.Timestamp

  // export all initialized services
  export { projectFirestore, projectAuth, projectStorage, timestamp, perf, analytics }