import React from 'react'
import { useState } from "react"
import { Link } from "react-router-dom"
import { projectAuth } from "../../firebase/config"
import WhipLogo from "../../assets/WHIP_LIST_COLOR.png"

//styles
import './ResetPassword.css'

export default function ResetPassword () {
    const [email, setEmail] = useState("")
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(null)

    const handleSubmit = async (e)  => {
        e.preventDefault()


        try {

            const actionCodeSettings = {
                url: "https://whiplist.io/login", 
                iOS: {bundleId: 'com.example.ios'},
                android: {
                    packageName: 'com.example.android',
                    installApp: true,
                    minimumVersion: '12'
                  },
                handleCodeInApp: false, 
                dynamicLinkDomain: "whiplist.io"
            }
            await projectAuth.sendPasswordResetEmail(email, actionCodeSettings)
            
            //Display successful send message, reset error
            setSent(true)
            setError(false)

            //Only show message for 5 seconds
            setTimeout(() => {setSent(false)}, 5000)
            
        }
        catch (err) {

            //Reset state of successful message, show error
            setSent(false)
            setError(err.message)

            //Only show message for 5 seconds
            setTimeout(() => {setError(null)}, 5000)
            
        }

    }

    return (
        <div className="backgroundgradient">
            <form onSubmit={handleSubmit}>

                <div className="big-logo-div">
                    <img src={WhipLogo} alt="WHIP_LIST_LOGO" className="big-logo"></img>
                </div>

                <h1 className="title">Reset Password</h1>
                <p>If there is an account tied to the entered email address, we will send a password reset email.</p>

                <label>
                    <span>Email:</span>
                    <input 
                    required
                    type="email"
                    placeholder="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    ></input>
                    {error && <div className="error">{error}</div>}
                    {sent && <div className="message">Email sent!</div>}
                </label>

                <button className="btn">Reset Password</button>

            </form>

            <div className="signup-link">
                <Link to="/signup" className="clickableLink">Don't have an account?</Link>
            </div>

        </div>
    )
}