import { useEffect, useState } from "react"
import { projectFirestore } from "../firebase/config"

export const useDocument = (collection, id) => {
    const [document, setDocument] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {

        // grab the document in question
        const ref = projectFirestore.collection(collection).doc(id)

        // set up real time listener for any changes to current document
        const unsubscribe = ref.onSnapshot((snapshot) => {
            if (snapshot.data()) {
                setDocument({...snapshot.data(), id: snapshot.id})
                setError(null)
            } else {
                setError('No such document exists')
            }
        }, (error) => {
            console.log(error.message)
            setError('Failed to get document')
        })

        // fire the cleanup function
        return () => {unsubscribe()}

    }, [collection, id])

    return {document, error}
}