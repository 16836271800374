import React from "react";

import { useState } from "react";

import PricingCard from "./PricingCard";

export default function PricingButtons () {

    const [active, setActive] = useState(true)

    const handleMonthlyClick = (e) => {
        setActive(true)
    }

    const handleAnnuallyClick = (e) => {
        setActive(false)
    }

    const monthlyPro = {
        name: "Pro", 
        subPrice: "9", 
        stickerPrice: "Free", 
        description: "Show multiple cars on your Whiplist page and make 10% affiliate commissions on all user signups through your link.", 
        label: "Recommended", 
        extra: null
    }
    const monthlyStarter = {
        name: "Starter", 
        subPrice: "5", 
        stickerPrice: "$5", 
        description: "Completely catalog all of the modified parts on your car and have your Whiplist URL match your username.", 
        label: "Most Popular", 
        extra: null
    }

    const free = {
        name: "Free", 
        subPrice: "0", 
        stickerPrice: "$15", 
        description: "Create your own basic Whiplist profile and follow your favorite users", 
        extra: null
    }

    const annuallyPro = {
        name: "Pro", 
        subPrice: "7.20", 
        stickerPrice: "Free", 
        description: "Show multiple cars on your Whiplist page and make 10% affiliate commissions on all user signups through your link.", 
        label: "Recommended", 
        extra: "Billed Annually"
    }
    const annuallyStarter = {
        name: "Starter", 
        subPrice: "4", 
        stickerPrice: "Free", 
        description: "Completely catalog all of the modified parts on your car and have your Whiplist URL match your username.", 
        label: "Most Popular", 
        extra: "Billed Annually"
    }

    return (
        <div className="buttonsDS">

            <div className="save">Save with annual plans</div>

            <div className="pricing-button-container">
                {active && <div className="pricing-button-active" onClick={handleMonthlyClick}>Monthly</div>}
                {active && <div className="pricing-button" onClick={handleAnnuallyClick}>Annually</div>}

                {!active && <div className="pricing-button" onClick={handleMonthlyClick}>Monthly</div>}
                {!active && <div className="pricing-button-active" onClick={handleAnnuallyClick}>Annually</div>}
            </div>

            <div className="cards-container">

                {active && <div className="monthly">
                    
                    <PricingCard info={monthlyPro}/>
                    <PricingCard info={monthlyStarter}/>
                    <PricingCard info={free}/>
                
                </div>}

                {!active && <div className="annually">

                    <PricingCard info={annuallyPro}/>
                    <PricingCard info={annuallyStarter}/>
                    <PricingCard info={free}/>
                    
                </div>}

            </div>

        </div>
    )
}